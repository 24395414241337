import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronRight, FaFile, FaUserFriends } from "react-icons/fa";
import { BiSpreadsheet, BiBookContent, BiSolidDashboard } from "react-icons/bi";
import { RiAdvertisementFill } from "react-icons/ri";
import {
  BsBank,
  BsBox2Fill,
  BsFillGearFill,
  BsInfoCircleFill,
  BsListCheck,
} from "react-icons/bs";
import { GrTransaction } from "react-icons/gr";
import { privateSlug } from "../../../../utils/privateSlug";
import { useTranslation } from "react-i18next";
import { TokenContext } from "../../../../controllers/public/TokenController";
import { AuthContext } from "../../../../controllers/public/AuthController";
import { useMutation } from "react-query";
import {
  getRole,
  getRolesSidebar,
} from "../../../content/private/User/UserAdminRole/api/RoleApi";
import { Badge } from "primereact/badge";
import MenuSidebar from "../../../../utils/menuItems";

const MenuItems = ({ item, level, rbacData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const { unreadData } = useContext(AuthContext);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  const ReactIcons = {
    //Icon for Dashboard
    Dashboard: BiSolidDashboard,
    Beranda: BiSolidDashboard,
    以下是您的详细信息更改密码: BiSolidDashboard,

    //Icon for Banner
    Banner: BiSpreadsheet,
    每月交易: BiSpreadsheet,

    //Icon for Ads
    Ads: RiAdvertisementFill,
    Iklan: RiAdvertisementFill,
    广告: RiAdvertisementFill,

    //Icon for product
    Product: BsBox2Fill,
    Produk: BsBox2Fill,
    产品: BsBox2Fill,

    //Icon for Bank Account
    "Bank Account": BsBank,
    "Akun Bank": BsBank,
    银行账户: BsBank,

    Bulk: FaFile,
    "Massal": FaFile,
    批量: FaFile,

    //Icon for Approval
    Approval: BsListCheck,
    Persetujuan: BsListCheck,
    批准: BsListCheck,

    //Icon for Transaction
    Transaction: GrTransaction,
    Transaksi: GrTransaction,
    交易: GrTransaction,

    //Icon for Config
    Config: BsFillGearFill,
    Konfigurasi: BsFillGearFill,
    配置: BsFillGearFill,

    //Icon for Nihao Care
    "NIHAO CARE": BsInfoCircleFill,

    //Icon for User
    user: FaUserFriends,
    pengguna: FaUserFriends,
    用户: FaUserFriends,

    //Icon for CMS
    CMS: BiBookContent,
    用户代理: BiBookContent,

    ChevronRight: FaChevronRight,
  };

  const indentation = level * 20;
  const Icon = ReactIcons[item.label];
  const isActive = item.link && location.pathname.endsWith(item.link);

  // const hasPermission = rbacData?.some(
  //   (rbacItem) =>
  //     // console.log(rbacItem.page, item.label.toLowerCase())
  //     rbacItem.key_page === item.link && rbacItem.access.includes("view")
  // );

  // if (!hasPermission && !(item.submenu && item.submenu.length > 0)) {
  //   return null; // If the user doesn't have permission for this item and it has no subitems, don't render it
  // }

  return (
    <li>
      <Link
        to={item.link || "#"}
        style={{ textDecoration: "none" }}
        onClick={() => item.submenu && toggleSubMenu()}
      >
        <div
          className={`flex pl-4 py-4 px-4 items-center ${
            isActive
              ? "bg-gray-300  cursor-pointer text-[#504178] font-[700] text-[14px]"
              : "hover:bg-gray-300 hover:cursor-pointer text-[#767D87] text-[14px]"
          }`}
          style={{ paddingLeft: `${20 + indentation}px` }}
        >
          {Icon && <Icon className="mr-3 text-xl" />}
          <span className="flex-1">
            {item.label === t("LABEL.NIHAO_CARE") && unreadData?.data?.qna ? (
              <div className="grid grid-cols-2">
                <div className="flex justify-start">
                  {t("LABEL.NIHAO_CARE")}
                </div>
                <div className="flex justify-end items-center">
                  <Badge severity="danger"></Badge>
                </div>
              </div>
            ) : item.label === t("LABEL.QNA_TICKET") &&
              unreadData?.data?.qna ? (
              <div className="grid grid-cols-2">
                <div className="flex justify-start">
                  {t("LABEL.QNA_TICKET")}
                </div>
                <div className="flex justify-end items-center">
                  <Badge severity="danger"></Badge>
                </div>
              </div>
            ) : (
              item.label
            )}
          </span>
          {item.submenu && (
            <span
              className={`w-4 h-4 ml-1 ${
                isOpen ? "transform rotate-0" : "transform rotate-90"
              } transition-transform duration-150`}
              onClick={toggleSubMenu}
            >
              <FaChevronRight />
            </span>
          )}
        </div>
      </Link>
      {/* {item.submenu && isOpen && (
        <ul>
          {item.submenu.map((subItem) => (
            <MenuItems
              key={subItem.label}
              item={subItem}
              level={level + 1.6}
              rbacData={rbacData}
            />
          ))}
        </ul>
      )} */}
      {item?.submenu && isOpen && (
        <ul>
          {item?.submenu?.map((subItem) => {
            const subItemHasPermission = rbacData?.some(
              (rbacItem) =>
                rbacItem.key_page === subItem.link &&
                rbacItem.access.includes("view")
            );

            if (
              !subItemHasPermission &&
              !(subItem.submenu && subItem.submenu.length > 0)
            ) {
              return null; // If the user doesn't have permission for this subitem and it has no subitems, don't render it
            }

            return (
              <MenuItems
                key={subItem.label}
                item={subItem}
                level={level + 1.6}
                rbacData={rbacData}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { token } = useContext(TokenContext);
  const { profileData } = useContext(AuthContext);
  const language = t("LANGUAGE");
  const [rbacData, setRbacData] = useState();
  const menuItems = MenuSidebar();

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    mutateGetRole(
      { guid: profileData?.data?.role.role_id, token, language },
      {
        onSuccess: (data) => {
          setRbacData(data?.data?.access);
        },
      }
    );
  }, [token, language, profileData]);

  const filterJson = (json1, json2) => {
    return json2?.filter((item2) => {
      if (item2.submenu) {
        // If the item has a submenu, recursively filter its submenu items
        item2.submenu = filterJson(json1, item2.submenu);
        return item2.submenu.length > 0;
      } else {
        // If the item does not have a submenu, check if it exists in json1
        return json1?.some((item1) => item1.key_page === item2.link);
      }
    });
  };

  return (
    <div className="bg-[#F3F1F1] mt-16 font-poppins w-64 fixed left-0 overflow-y-auto h-screen pb-20">
      <div className="pt-2">
        <ul className="space-y-1">
          {filterJson(rbacData, menuItems)?.map((item) => (
            <MenuItems
              key={item.label}
              item={item}
              level={0}
              rbacData={rbacData}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
