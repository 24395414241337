import React, { useContext } from "react";

import IconWarning from "../../../../../../assets/img/warning.png";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "@chakra-ui/react";
import { BulkContext } from "../../Controller";
import { useTranslation } from "react-i18next";

const ExecuteConfirmationDialog = () => {
  const {
    isVisibleExecuteBulk,
    BulkId,
    handleDialogExecuteBulk,
    handleExecuteBulk,
    isLoadingExecuteBulk,
  } = useContext(BulkContext);
  const { t } = useTranslation();
  return (
    <div>
      <ConfirmDialog
        visible={isVisibleExecuteBulk}
        message={
          <div className="grid grid-cols-1 font-poppins">
            <div className="flex justify-center items-center">
              <img src={IconWarning} alt="" />,
            </div>
            <span className="py-4 leading-[24px] text-[16px] font-[700] text-[#171717]">
              {t("MESSAGE.EXECUTE_VALIDATION")}
            </span>
          </div>
        }
        closable={false}
        footer={
          <div className="grid grid-cols-1">
            <div className="flex justify-center items-center">
              <Button
                size={"md"}
                colorScheme="new"
                className="bg-[#FFFFFF]  border-2 border-[#504178] hover:bg-[#C7C4CF] font-poppins"
                style={{
                  borderRadius: "100px",
                  width: "182px",
                  color: "#504178",
                }}
                onClick={() => handleDialogExecuteBulk(false, BulkId)}
              >
                {t("LABEL.CANCEL")}
              </Button>
              <Button
                size={"md"}
                colorScheme="new"
                className="bg-[#504178] text-[#FFFFFF] hover:bg-[#402b79] font-poppins"
                style={{ borderRadius: "100px", width: "182px" }}
                isLoading={isLoadingExecuteBulk}
                onClick={() => handleExecuteBulk(BulkId)}
              >
                {t("LABEL.YES")}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ExecuteConfirmationDialog;
