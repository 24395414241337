import { api } from "../../../../../utils/axios";

//Get List Uploaded Excel
export const getListUploadedExcel = ({ page, limit, token, language }) =>
  api
    .post(
      "/backoffice/transaction/ppob-bulk-excel/list",
      {
        filter: {
          set_transaction_bulk_excel_id: false,
          transaction_bulk_excel_id: "",
          set_doc_name: false,
          doc_name: "",
          set_status: false,
          status: "",
          set_created_at_range: false,
          created_at_start: null,
          created_at_end: null,
        },
        limit: limit || 10,
        page: page || 1,
        order: "created_at",
        sort: "DESC",
      },
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Set Upload Excel
export const setUploadExcel = ({ payloadPublish, token, language }) =>
  api
    .post("/backoffice/transaction/ppob-bulk-excel", payloadPublish, {
      headers: {
        language: language,
        "nihao-token": token,
      },
    })
    .then((res) => res?.data);

//Delete Data
export const deleteUploadExcel = ({
  transaction_bulk_excel_id,
  token,
  language,
}) =>
  api
    .delete(
      `/backoffice/transaction/ppob-bulk-excel/${transaction_bulk_excel_id}`,
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Set Process Data Upload
export const setProcessDataUpload = ({
  payloadEditPublish,
  transaction_bulk_excel_id,
  token,
  language,
}) =>
  api
    .patch(
      `/backoffice/transaction/ppob-bulk-excel/process/${transaction_bulk_excel_id}`,
      payloadEditPublish,
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);

//Set Process Data Upload
export const getDownloadBulk = ({ token, language, payload }) =>
  api
    .get(
      `/backoffice/transaction/ppob-bulk-excel/${payload?.transaction_bulk_excel_id}`,
      {
        headers: {
          language: language,
          "nihao-token": token,
        },
      }
    )
    .then((res) => res?.data);
