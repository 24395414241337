import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { TokenContext } from "../../../../controllers/public/TokenController";
import {
  getListUploadedExcel,
  deleteUploadExcel,
  setProcessDataUpload,
  getDownloadBulk,
} from "./api/BulkApi";
import { getProfile } from "../../../../controllers/public/api/ProfileApi";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../controllers/public/AuthController";
import moment from "moment";
import { getRole } from "../User/UserAdminRole/api/RoleApi";
import { api } from "../../../../utils/axios";
import * as XLSX from "xlsx";

export const BulkContext = createContext();
const { Provider } = BulkContext;

const BulkController = ({ children }) => {
  const { t } = useTranslation();
  const language = t("LANGUAGE");
  const { token } = useContext(TokenContext);
  const toast = useToast();

  const {
    data: bulkData,
    isLoading: isLoadingBulkData,
    refetch: refetchBulkData,
  } = useQuery(
    ["listbulk", token, language],
    () => getListUploadedExcel({ token, language }),
    {
      // Refetch the data every 1 second
      refetchInterval: 300000,
    }
  );

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    isError: errorProfileData,
  } = useQuery(["profile", token, language], () =>
    getProfile({ token, language })
  );

  // const {
  //   isLoading: isLoadingBulkSearch,
  //   mutate: mutateSetBankCodeSearch,
  // } = useMutation(getBulkSearch);

  const { mutate: mutateGetListUploadedExcel } =
    useMutation(getListUploadedExcel);
  // const { mutate: mutateSetBulkActive } =
  //   useMutation(setBulkActive);

  const { isLoading: isLoadingDeleteBulk, mutate: mutateDeleteBulk } =
    useMutation(deleteUploadExcel);
  const { isLoading: isLoadingExecuteBulk, mutate: mutateExecuteBulk } =
    useMutation(setProcessDataUpload);
  const { isLoading: isLoadingDownloadBulk, mutate: mutateDownloadBulk } =
    useMutation(getDownloadBulk);

  // const {
  //   isLoading: isLoadingBulkEdit,
  //   mutate: mutateSetBulkEdit,
  // } = useMutation(setBulkEdit);

  // const { isLoading: isLoadingGetSyncMoota, mutate: mutateGetSyncMoota } =
  //   useMutation(getSyncMoota);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [isVisibleDeleteBulk, setIsVisibleDeleteBulk] = useState(false);
  const [isVisibleDeleteInfoBulk, setIsVisibleDeleteInfoBulk] = useState(false);

  const [isVisibleExecuteBulk, setIsVisibleExecuteBulk] = useState(false);
  const [isVisibleExecuteInfoBulk, setIsVisibleExecuteInfoBulk] =
    useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isMoota, setIsMoota] = useState(false);
  const [isGuidBca, setIsGuidBca] = useState(false);
  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [urlProfilePicture, setUrlProfilePicture] = useState();
  const [urlQrCode, setUrlQrCode] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [isShowTemporaryClose, setIsShowTemporaryClose] = useState(false);

  const [newBulksData, setNewBulksData] = useState();
  const [BulkId, setBulkId] = useState();
  const [agentId, setAgentId] = useState();
  const [newBulkSearch, setNewBulkSearch] = useState();

  const [isShowType, setIsShowType] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(false);
  const [isQrCode, setIsQrCode] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  let newBulkData = [];
  let currentPage = [];
  let currentLimit = [];

  const [isDeleteAccessUserAgent, setIsDeleteAccessUserAgent] = useState();
  const [isViewAllAccess, setIsViewAllAccess] = useState();
  const { roleIdUser, listAccess, setListAccess } = useContext(AuthContext);

  const { mutate: mutateGetRole } = useMutation(getRole);

  useEffect(() => {
    const guid = roleIdUser;
    mutateGetRole(
      { guid, token },
      {
        onSuccess: (data) => {
          setListAccess(data?.data?.access);
          setIsViewAllAccess(data?.data?.is_all_access);

          //User Agent
          const deleteAccessUserAgent = data?.data?.access
            ?.filter((data) => data?.page === "agent")
            .map((newData) => newData)[0]
            ?.access?.filter((newAccess) => newAccess === "delete");
          setIsDeleteAccessUserAgent(deleteAccessUserAgent?.length);
        },
      }
    );
  }, [roleIdUser]);

  // -------------------------------
  // Form Modal
  // -------------------------------
  const formModal = useForm();
  const resetformModal = (newForm) => {
    if (newForm) {
      formModal.reset({
        guid: newForm?.guid,
        is_qr_code: newForm?.is_qr_code,
        bank_code: newForm?.bank_code,
        bank_name: newForm?.bank_name,
        bank_logo_url: newForm?.bank_logo_url,
        bank_qr_code_url: newForm?.bank_qr_code_url,
        account_number: newForm?.account_number,
        account_name: newForm?.account_name,
        platform_fee: newForm?.platform_fee,
        platform_fee_type: newForm?.platform_fee_type,
        payment_service_fee: newForm?.payment_service_fee,
        payment_service_fee_decimal: newForm?.payment_service_fee_decimal,
        payment_service_fee_percentage: newForm?.payment_service_fee_percentage,
        payment_service_fee_type: newForm?.payment_service_fee_type,
        description: newForm?.description,
        is_active_schedule: newForm?.is_active_schedule,
        schedule_start:
          newForm?.schedule_start &&
          formatScheduleTime(newForm?.schedule_start),
        schedule_end:
          newForm?.schedule_end && formatScheduleTime(newForm?.schedule_end),
      });
    }
  };

  // -------------------------------
  // Form Modal Transaction
  // -------------------------------
  const formTransaction = useForm();
  const resetformTransaction = (newForm) => {
    if (newForm) {
      formTransaction.reset({
        firstname: newForm?.firstname,
        lastname: newForm?.lastname,
        description: newForm?.description,
        address: newForm?.address,
        balance: newForm?.balance,
        last_login: newForm?.last_login,
      });
    }
  };

  const formKeywordSearch = useForm();
  const resetFormSearch = (newForm) => {
    if (newForm) {
      formKeywordSearch.reset({
        keyword_search: newForm?.keyword_search,
        keyword_filter: newForm?.keyword_filter,
        keyword_status: newForm?.keyword_status,
      });
    }
  };

  //Formatted for Schedule time
  const formatScheduleTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  };

  const handleModalFormOpen = (isCreate, isOpen, isEdit, guid, isMoota) => {
    setIsModalFormOpen(isOpen);
    setIsEdit(isEdit);
    isCreate && resetformModal({});
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await fetch("/template/template_import.xls");

      if (!response.ok) {
        throw new Error("File not found or unable to fetch the file.");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "template_import.xls");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  const handleDialogExecuteBulk = (isOpen, transaction_bulk_excel_id) => {
    setIsVisibleExecuteBulk(isOpen);
    setBulkId(transaction_bulk_excel_id);
    setIsVisibleExecuteInfoBulk(false);
  };

  const handleExecuteBulk = (transaction_bulk_excel_id) => {
    mutateExecuteBulk(
      { transaction_bulk_excel_id, token, language },
      {
        onSuccess: () => {
          refetchBulkData();
          setIsVisibleExecuteBulk(false);
          setIsVisibleExecuteInfoBulk(true);
        },
      }
    );
  };

  const handleDialogDeleteBulk = (isOpen, transaction_bulk_excel_id) => {
    setIsVisibleDeleteBulk(isOpen);
    setBulkId(transaction_bulk_excel_id);
    setIsVisibleDeleteInfoBulk(false);
  };

  const handleDeleteBulk = (transaction_bulk_excel_id) => {
    mutateDeleteBulk(
      { transaction_bulk_excel_id, token, language },
      {
        onSuccess: () => {
          refetchBulkData();
          setIsVisibleDeleteBulk(false);
          setIsVisibleDeleteInfoBulk(true);
        },
      }
    );
  };

  const handleDownloadBulk = (transaction_bulk_excel_id) => {
    // let limit = 100000;
    // let page = 1;
    let payload = {
      transaction_bulk_excel_id: transaction_bulk_excel_id,
    };

    mutateDownloadBulk(
      { token, language, payload },
      {
        onSuccess: (data) => {
          const filteredData = data?.data.map(
            ({
              // excel_id,
              transaction_id,
              destination_number,
              buyer_sku_code,
              // brand,
              // type,
              // seller_name,
              product_name,
              biller_price,
              // description,
              // biller_ref_id,
              biller_sn,
              // biller_rc,
              biller_status,
              biller_message,
              data_process_status,
              created_at,
              processing_at,
            }) => ({
              // excel_id,
              transaction_id,
              destination_number,
              buyer_sku_code,
              // brand,
              // type,
              // seller_name,
              product_name,
              biller_price,
              // description,
              // biller_ref_id,
              biller_sn,
              // biller_rc,
              biller_status,
              biller_message,
              data_process_status,
              created_at,
              processing_at,
            })
          );

          // Create a new worksheet from the JSON data
          const worksheet = XLSX.utils.json_to_sheet(filteredData);

          // Set custom headers with titles
          const customHeader = [
            // "Excel ID",
            "Transaction ID",
            "Destination Number",
            "Buyer SKU Code",
            // "Brand",
            // "Type",
            // "Seller Name",
            "Product Name",
            "Biller Price",
            // "Description",
            // "Reference Biller Number",
            "Biller SN",
            // "Biller RC",
            "Biller Status",
            "Biller Message",
            "Status",
            "Created At",
            "Processing At",
          ];

          // Insert the header at the top
          XLSX.utils.sheet_add_aoa(worksheet, [customHeader], { origin: "A1" });

          // Define the range for styling (headers in row 1)
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
            if (!worksheet[cellAddress]) continue;

            // Apply bold font and background gray to each header cell
            worksheet[cellAddress].s = {
              font: { bold: true },
              fill: {
                fgColor: { rgb: "D3D3D3" }, // light gray background
              },
              alignment: {
                horizontal: "center", // center alignment (optional)
              },
            };
          }

          // Create a new workbook and append the worksheet
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

          // Convert the workbook to a blob and trigger the download
          XLSX.writeFile(
            workbook,
            "REPORT-" + transaction_bulk_excel_id + ".xlsx"
          );
        },
      }
    );
  };

  // -------------------------------
  // Show Entries
  // -------------------------------

  const handleShowEntriesTable = (limit) => {
    setIsSearch(false);
    mutateGetListUploadedExcel(
      { limit, token, language },
      {
        onSuccess: (data) => {
          newBulkData = [];

          data?.data?.map((value) => {
            newBulkData.push({
              transaction_bulk_excel_id: value?.transaction_bulk_excel_id,
              doc_name: value?.doc_name,
              total_data: value?.total_data,
              total_uploaded_data: value?.total_uploaded_data,
              total_success: value?.total_success,
              total_failed: value?.total_failed,
              total_pending: value?.total_pending,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              status:
                value?.status === "created" ? (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.CREATED")}
                  </div>
                ) : value?.status === "processing" &&
                  value?.total_success !== 0 &&
                  value?.total_success !== 0 ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.PROCESSING")}
                  </div>
                ) : value?.status === "finished" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.FINISHED")}
                  </div>
                ) : value?.status === "processing" &&
                  value?.total_success === 0 &&
                  value?.total_failed === 0 ? (
                  <div className="bg-[#cb772c] text-[#ffffff] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Pending
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]"></div>
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {value?.status === "created" ? (
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#46BC78]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogExecuteBulk(
                            true,
                            value?.transaction_bulk_excel_id
                          )
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.EXECUTE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteBulk(
                            true,
                            value?.transaction_bulk_excel_id
                          )
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    </>
                  ) : value?.status === "finished" ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDownloadBulk(value?.transaction_bulk_excel_id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DOWNLOAD")}
                      </div>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ),
            });
          });
          setNewBulksData(newBulkData);
        },
      }
    );
  };

  // -------------------------------
  // Pagination
  // -------------------------------

  const handlePaginationTable = (page, limit) => {
    setIsSearch(false);
    mutateGetListUploadedExcel(
      { page, limit, token, language },
      {
        onSuccess: (data) => {
          newBulkData = [];

          data?.data?.map((value) => {
            newBulkData.push({
              transaction_bulk_excel_id: value?.transaction_bulk_excel_id,
              doc_name: value?.doc_name,
              total_data: value?.total_data,
              total_uploaded_data: value?.total_uploaded_data,
              total_success: value?.total_success,
              total_failed: value?.total_failed,
              total_pending: value?.total_pending,
              created_at: moment(value?.created_at).format(
                "MMMM Do YYYY, HH:mm:ss"
              ),
              status:
                value?.status === "created" ? (
                  <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.CREATED")}
                  </div>
                ) : value?.status === "processing" &&
                  value?.total_success !== 0 &&
                  value?.total_success !== 0 ? (
                  <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.PROCESSING")}
                  </div>
                ) : value?.status === "finished" ? (
                  <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    {t("LABEL.FINISHED")}
                  </div>
                ) : value?.status === "processing" &&
                  value?.total_success === 0 &&
                  value?.total_failed === 0 ? (
                  <div className="bg-[#cb772c] text-[#ffffff] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
                    Pending
                  </div>
                ) : (
                  <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]"></div>
                ),
              action: (
                <div className="flex items-center justify-center gap-2">
                  {value?.status === "created" ? (
                    <>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#46BC78]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogExecuteBulk(
                            true,
                            value?.transaction_bulk_excel_id
                          )
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.EXECUTE")}
                        </div>
                      </Button>
                      <Button
                        size="md"
                        colorScheme="new"
                        className="bg-[#FF0F0F]"
                        style={{ borderRadius: "10px", height: "23px" }}
                        onClick={() =>
                          handleDialogDeleteBulk(
                            true,
                            value?.transaction_bulk_excel_id
                          )
                        }
                      >
                        <div className=" text-white leading-[15px] font-[600] text-[12px]">
                          {t("LABEL.DELETE")}
                        </div>
                      </Button>
                    </>
                  ) : value?.status === "finished" ? (
                    <Button
                      size="md"
                      colorScheme="new"
                      className="bg-[#504178]"
                      style={{ borderRadius: "10px", height: "23px" }}
                      onClick={() =>
                        handleDownloadBulk(value?.transaction_bulk_excel_id)
                      }
                    >
                      <div className=" text-white leading-[15px] font-[600] text-[12px]">
                        {t("LABEL.DOWNLOAD")}
                      </div>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ),
            });
          });
          setNewBulksData(newBulkData);
        },
      }
    );
  };

  useEffect(() => {
    newBulkData = [];
    setIsSearch(false);

    bulkData?.data?.map((value) => {
      newBulkData.push({
        transaction_bulk_excel_id: value?.transaction_bulk_excel_id,
        doc_name: value?.doc_name,
        total_data: value?.total_data,
        total_uploaded_data: value?.total_uploaded_data,
        total_success: value?.total_success,
        total_failed: value?.total_failed,
        total_pending: value?.total_pending,
        created_at: moment(value?.created_at).format("MMMM Do YYYY, HH:mm:ss"),
        status:
          value?.status === "created" ? (
            <div className="bg-[#DCD9E4] text-[#504178] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.CREATED")}
            </div>
          ) : value?.status === "processing" &&
            value?.total_success !== 0 &&
            value?.total_success !== 0 ? (
            <div className="bg-[#faf089] text-[#b7791f] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.PROCESSING")}
            </div>
          ) : value?.status === "finished" ? (
            <div className="bg-[#BAE6CC] text-[#18AB56] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              {t("LABEL.FINISHED")}
            </div>
          ) : value?.status === "processing" &&
            value?.total_success === 0 &&
            value?.total_failed === 0 ? (
            <div className="bg-[#cb772c] text-[#ffffff] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]">
              Pending
            </div>
          ) : (
            <div className="bg-[#F4F4F4] text-[#747474] leading-[15px] font-[600] text-[12px] flex items-center justify-center h-[31px] rounded-[10px]"></div>
          ),
        action: (
          <div className="flex items-center justify-center gap-2">
            {value?.status === "created" ? (
              <>
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#46BC78]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() =>
                    handleDialogExecuteBulk(
                      true,
                      value?.transaction_bulk_excel_id
                    )
                  }
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.EXECUTE")}
                  </div>
                </Button>
                <Button
                  size="md"
                  colorScheme="new"
                  className="bg-[#FF0F0F]"
                  style={{ borderRadius: "10px", height: "23px" }}
                  onClick={() =>
                    handleDialogDeleteBulk(
                      true,
                      value?.transaction_bulk_excel_id
                    )
                  }
                >
                  <div className=" text-white leading-[15px] font-[600] text-[12px]">
                    {t("LABEL.DELETE")}
                  </div>
                </Button>
              </>
            ) : value?.status === "finished" ? (
              <Button
                size="md"
                colorScheme="new"
                className="bg-[#504178]"
                style={{ borderRadius: "10px", height: "23px" }}
                onClick={() =>
                  handleDownloadBulk(value?.transaction_bulk_excel_id)
                }
              >
                <div className=" text-white leading-[15px] font-[600] text-[12px]">
                  {t("LABEL.DOWNLOAD")}
                </div>
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      });
    });
    setNewBulksData(newBulkData);
  }, [bulkData, token, isDeleteAccessUserAgent, isViewAllAccess]);

  return (
    <Provider
      value={{
        formModal,

        resetformModal,

        isModalFormOpen,
        isVisibleDeleteBulk,
        isVisibleDeleteInfoBulk,
        isVisibleExecuteBulk,
        isVisibleExecuteInfoBulk,
        isEdit,

        bulkData,
        BulkId,
        newBulksData,

        isLoadingBulkData,
        // isLoadingGetUsers,
        // isLoadingDeleteUser,

        handleModalFormOpen,
        handleDialogDeleteBulk,

        handleDeleteBulk,
        handleExecuteBulk,
        handlePaginationTable,
        handleShowEntriesTable,

        urlProfilePicture,
        setUrlProfilePicture,

        isDeleteAccessUserAgent,
        isViewAllAccess,
        isSearch,
        isShowTemporaryClose,

        // isLoadingBulkEdit,

        agentId,

        isShowType,
        isShowStatus,
        refetchBulkData,
        bankLogoUrl,
        setBankLogoUrl,
        qrCodeUrl,
        setQrCodeUrl,
        isQrCode,
        setIsQrCode,
        isSchedule,
        setIsSchedule,
        urlQrCode,
        setUrlQrCode,
        // isLoadingGetSyncMoota,
        isMoota,
        isGuidBca,

        newBulkSearch,
        setNewBulkSearch,
        setIsShowStatus,
        formKeywordSearch,

        handleDownloadTemplate,
      }}
    >
      {children}
    </Provider>
  );
};

export default BulkController;
