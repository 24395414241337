// menuItems.js

import { useTranslation } from "react-i18next";
import { privateSlug } from "./privateSlug";

const MenuSidebar = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("LABEL.DASHBOARD"),
      link: privateSlug.DASHBOARD,
    },
    {
      label: t("LABEL.BANNER"),
      link: privateSlug.BANNER,
    },
    {
      label: t("LABEL.ADS"),
      submenu: [
        {
          label: t("LABEL.ADVERTISEMENT"),
          link: privateSlug.ADS + privateSlug.ADVERTISING,
        },
        {
          label: t("LABEL.SECTION"),
          submenu: [
            {
              label: t("LABEL.EDIT") + " " + t("LABEL.SECTION"),
              link: privateSlug.SECTION,
            },
          ],
        },
        {
          label: t("LABEL.ANNOUNCEMENT"),
          link: privateSlug.ADS + privateSlug.CLASSIFIED,
        },
      ],
    },
    {
      label: t("LABEL.PRODUCT"),
      submenu: [
        {
          label: t("LABEL.PREPAID"),
          link: privateSlug.PRODUCTS + privateSlug.PRABAYAR,
        },
        {
          label: t("LABEL.POSTPAID"),
          link: privateSlug.PRODUCTS + privateSlug.PASCABAYAR,
        },
      ],
    },
    {
      label: t("LABEL.BANK_ACCOUNT"),
      link: privateSlug.BANKACCOUNT,
    },
    {
      label: t("LABEL.BULK"),
      link: privateSlug.BULK,
    },
    {
      label: t("LABEL.APPROVAL"),
      submenu: [
        {
          label: t("LABEL.PAYMENT"),
          link: privateSlug.CONFIRMATION + privateSlug.APPROVAL,
        },
        {
          label: t("LABEL.CUSTOMS_DECLARATION"),
          link: privateSlug.CUSTOMSDECLARATION + privateSlug.APPROVE,
        },
      ],
    },
    {
      label: t("LABEL.TRANSACTION"),
      submenu: [
        {
          label: t("LABEL.HISTORY"),
          submenu: [
            {
              label: t("LABEL.HISTORY") + " " + t("LABEL.PPOB"),
              link: privateSlug.TRANSACTION + privateSlug.PRABAYAR,
            },
            {
              label: t("LABEL.HISTORY") + " " + t("LABEL.CUSTOMS_DECLARATION"),
              link: privateSlug.CUSTOMSDECLARATION + privateSlug.TRANSACTION,
            },
            {
              label: t("LABEL.TRANSACTION_SH"),
              link: privateSlug.TRANSACTIONSH,
            },
          ],
        },
        {
          label: t("LABEL.PAYMENT"),
          link: privateSlug.CONFIRMATION + privateSlug.CONFIRMATIONTRANSACTION,
        },
        {
          label: t("LABEL.POINT"),
          link: privateSlug.POINT,
        },
      ],
    },
    {
      label: t("LABEL.CONFIG"),
      // label: "Config",
      submenu: [
        {
          label: t("LABEL.GLOBAL_CONFIG"),
          link: privateSlug.CONFIG,
        },
        {
          label: t("LABEL.VOUCHER"),
          submenu: [
            {
              label: t("LABEL.GENERATE") + " " + t("LABEL.VOUCHER"),
              link: privateSlug.VOUCHER,
            },
          ],
        },
      ],
    },
    {
      label: t("LABEL.NIHAO_CARE"),
      // label: "Nihao Care",
      submenu: [
        {
          label: t("LABEL.TOPIC"),
          link: privateSlug.MASTER + privateSlug.TOPIC,
        },
        {
          label: t("LABEL.TOPIC_CATEGORY"),
          link: privateSlug.MASTER + privateSlug.TOPICCATEGORY,
        },
        {
          label: t("LABEL.QNA"),
          link: privateSlug.MASTER + privateSlug.QNA,
        },
        {
          label: t("LABEL.QNA_TICKET"),
          link: privateSlug.MASTER + privateSlug.QNATICKET,
        },
        {
          label: t("LABEL.INFO"),
          link: privateSlug.INFO,
        },
      ],
    },
    {
      label: t("LABEL.USER"),
      submenu: [
        {
          label: t("LABEL.USER_ADMIN"),
          link: privateSlug.USER + privateSlug.ADMIN,
        },
        {
          label: t("LABEL.USER_HANDHELD"),
          link: privateSlug.USER + privateSlug.HANDHELD,
        },
        {
          label: t("LABEL.USER_ADMIN") + " " + t("LABEL.ROLE"),
          link: privateSlug.USER + privateSlug.ROLE,
        },
        {
          label: t("LABEL.USER_AGENT"),
          link: privateSlug.USER + privateSlug.AGENT,
        },
      ],
    },
    {
      label: t("LABEL.CMS"),
      submenu: [
        {
          label: t("LABEL.TERMS"),
          link: privateSlug.CMS + privateSlug.TERMSCONDITION,
        },
        {
          label: t("LABEL.FAQ"),
          link: privateSlug.CMS + privateSlug.FAQ,
        },
        {
          label: t("LABEL.PRIVACY"),
          link: privateSlug.CMS + privateSlug.PRIVACYPOLICY,
        },
        {
          label: t("LABEL.ABOUT"),
          link: privateSlug.CMS + privateSlug.ABOUTUS,
        },
      ],
    },
  ];
};

export default MenuSidebar;
