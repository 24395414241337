import React, { lazy, Suspense } from "react";
import BulkController from "./Controller";
import ExecuteConfirmationDialog from "./components/Dialog/ExecuteConfirmationDialog";
import ExecuteInformationDialog from "./components/Dialog/ExecuteInformationDialog";

const Bulk = () => {
  const Filter = lazy(() => import("./components/Filter"));
  const ButtonNew = lazy(() => import("./components/ButtonNew"));
  const ModalBulk = lazy(() => import("./components/Modal"));
  const TableBulk = lazy(() => import("./components/TableBulk"));
  const DeleteConfirmationDialog = lazy(() =>
    import("./components/Dialog/DeleteConfirmationDialog")
  );
  const DeleteInformationDialog = lazy(() =>
    import("./components/Dialog/DeleteInformationDialog")
  );

  return (
    <div>
      <BulkController>
        <Suspense
          fallback={
            <div class="h-screen flex items-center">
              <div class="mx-auto">
                <div
                  class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex gap-4">
            <ButtonNew />
          </div>
          <div className="pt-4">
            <div className="overflow-auto h-screen">
              <TableBulk />
            </div>
          </div>
          <ModalBulk />
          <DeleteConfirmationDialog />
          <DeleteInformationDialog />
          <ExecuteConfirmationDialog />
          <ExecuteInformationDialog />
        </Suspense>
      </BulkController>
    </div>
  );
};

export default Bulk;
