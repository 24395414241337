import React, { useContext } from "react";

import IconSuccess from "../../../../../../assets/img/success.png";
import { ConfirmDialog } from "primereact/confirmdialog";
import { BulkContext } from "../../Controller";
import { useTranslation } from "react-i18next";

const ExecuteInformationDialog = () => {
  const { isVisibleExecuteInfoBulk } = useContext(BulkContext);
  const { t } = useTranslation();
  return (
    <div>
      <ConfirmDialog
        visible={isVisibleExecuteInfoBulk}
        message={
          <div className="grid grid-cols-1 font-poppins">
            <div className="flex justify-center items-center">
              <img src={IconSuccess} alt="" />,
            </div>
            <span className="py-4 leading-[24px] text-[16px] font-[700] text-[#171717]">
              {t("MESSAGE.EXECUTE_SUCCESS")}
            </span>
            <span>{t("MESSAGE.EXECUTE_INFO_SUCCESS")}</span>
          </div>
        }
        closable={true}
        footer={<div></div>}
      />
    </div>
  );
};

export default ExecuteInformationDialog;
